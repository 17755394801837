/* eslint-disable max-len */

'use strict';

/* eslint no-param-reassign: 0, max-len: 0 */
define('vbtu/actionChainTester',[], () => {
  // mapping for actions that need to be stubbed
  const actionStubMap = {
    'vb/action/builtin/fireCustomEventAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/callComponentMethodAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/fireDataProviderEventAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/fireNotificationEventAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/loginAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/logoutAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/navigateToPageAction': 'vbtu/actionStubs/navigateToPageActionStub',
    'vb/action/builtin/navigateAction': 'vbtu/actionStubs/navigateActionStub',
    'vb/action/builtin/navigateBackAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/openUrlAction': 'vbtu/actionStubs/actionStub',
    'vb/action/builtin/webShareAction': 'vbtu/actionStubs/actionStub',
  };

  // list of actions that should be no-op if no mock is provided
  const noopActions = [
    'vb/action/builtin/restAction',
    'vb/action/builtin/takePhotoAction',
    'vb/action/builtin/geolocationAction',
  ];

  // mapping for RT modules to be loaded
  const rtModuleMap = {
    ConfigLoader: 'vb/private/configLoader',
    Container: 'vb/private/stateManagement/container',
    Application: 'vb/private/stateManagement/application',
    AppPackage: 'vb/private/stateManagement/appPackage',
    Flow: 'vb/private/stateManagement/flow',
    PackageFlow: 'vb/private/stateManagement/packageFlow',
    Page: 'vb/private/stateManagement/page',
    PackagePage: 'vb/private/stateManagement/packagePage',
    Layout: 'vb/private/stateManagement/layout',
    LayoutExtension: 'vb/private/stateManagement/layoutExtension',
    Fragment: 'vb/private/stateManagement/fragment',
    PackageFragment: 'vb/private/stateManagement/packageFragment',
    FragmentExtension: 'vb/private/stateManagement/fragmentExtension',
    StateUtils: 'vb/private/stateManagement/stateUtils',
    ActionChain: 'vbtu/mocks/mockActionChain',
    Router: 'vb/private/stateManagement/router',
    StoreManager: 'vb/private/stateManagement/redux/storeManager',
    ExtensionRegistry: 'vbtu/mocks/mockExtensionRegistry',
    Utils: 'vb/private/utils',
    RuntimeEnvironment: 'vb/private/helpers/runtimeEnvironment',
    ActionChainUtils: 'vb/private/action/actionChainUtils',
    Constants: 'vb/private/constants',
    jsondiff: 'jsondiff',
  };

  // reg ex used to test if this is an extension
  const extensionRegEx = /ui\/([^/]+)\/(.+)$/;

  // reg ex used to determine the root container type, e.g., regular app, app UI or layout
  const rootContainerRegEx = /(?:(webApps|mobileApps)|(applications)|(dynamicLayouts)|(fragments))\/([^/]+)?\/(.+)$/;

  // reg ex used to determine the container in which the action chain will be executed
  const containerRegEx = /(?:((?:flows\/[^/]+\/)*)(?:flows\/([^/]+)\/))?(?:([^/]+)(?:-flow)?|pages\/([^/]+)-page)$/;

  // symbol used to query if an object is a translation proxy
  const isTranslationProxy = Symbol('is-translation-proxy');

  /**
   * Tester for running an action chain tests.
   */
  class ActionChainTester {
    constructor(testConfig = { context: {} }) {
      this.testConfig = Object.assign({}, testConfig);

      this.isJsChain = testConfig.options.isJsChain;

      // set up a dummy id generator if none provided
      this.generateId = testConfig.options.generateId || ((id) => id.replace('Actions.', ''));

      // if true, create a mock app ui
      this.mockAppUi = false;

      // make the current instance available to static mockContainerDescriptor
      // TODO: find a better way to do this
      ActionChainTester.instance = this;

      const baseUrlToken = window.vbInitConfig.BASE_URL_TOKEN ? `${window.vbInitConfig.BASE_URL_TOKEN}/` : '';
      let appPath = '';
      let path = testConfig.path;

      let matches = path.match(extensionRegEx);
      if (matches) {
        this.extensionId = matches[1];
        path = matches[2];

        if (this.extensionId === 'self') {
          this.isSelfExtension = true;
        } else if (path.endsWith('-x')) {
          path = path.substring(0, path.length - 2);
        }
      }

      if (path === 'app-flow') {
        this.isAppFlow = true;
      } else {
        matches = path.match(rootContainerRegEx);
        if (matches) {
          if (matches[1]) {
            // matches webApps or mobileApps
            appPath = `${matches[1]}/${matches[5]}/`;

            // handle the case for webApps/myApp/fragments/... where matches[6] is fragments/...
            matches = matches[6].match(rootContainerRegEx) || matches;
          }

          if (matches[2]) {
            // matches applications (app ui)
            this.appUiId = matches[5];
          } else if (matches[3]) {
            // matches dynamicLayouts
            this.layoutId = 'layout';
            let layoutRequirePath = matches[0];
            layoutRequirePath = layoutRequirePath.substring(0, layoutRequirePath.lastIndexOf('/') + 1);

            const extensionId = matches[5];
            if (extensionId === 'self' || matches[6].endsWith('-x')) {
              // handle v2 extension layout
              this.extensionId = extensionId;
              this.isSelfExtension = extensionId === 'self';

              // use a fake app ui id for loading the mock app ui container
              this.appUiId = 'mockAppUi';

              // for layout-x we need to load the base layout from dynamicLayouts/self
              if (!this.isSelfExtension) {
                layoutRequirePath = layoutRequirePath
                  .replace(`dynamicLayouts/${extensionId}/`, 'dynamicLayouts/self/');
              }
            } else {
              // Since the DT puts a layout in the dynamicLayouts folder that is at the same level as webApps,
              // the tester needs to know the application path in which the layout will be embedded. The agreement
              // is to specify this via vbInitConfig.APP_PATH property instead of hard-coding it in the test
              // itself.
              appPath = window.vbInitConfig.APP_PATH;
              if (!appPath.endsWith('/')) {
                appPath = `${appPath}/`;
              }

              // If baseUrlToken is not defined, need to go up two levels to be at the same level as webApps in order
              // to access dynamicLayouts folder. Otherwise, dynamicLayouts folder is at the same
              // level as the app-flow.json.
              if (!baseUrlToken) {
                layoutRequirePath = `../../${layoutRequirePath}`;
              }
            }

            this.layoutRequirePath = layoutRequirePath;
          } else if (matches[4]) {
            if (this.extensionId) {
              // use a fake app ui id for loading the mock app ui container
              this.appUiId = 'mockAppUi';
            }
            this.fragmentId = matches[5];
          }
          path = matches[6];
        } else {
          throw new Error(`Failed to determine the root container from ${testConfig.path}`);
        }

        if (!this.layoutId && !this.fragmentId) {
          // parse the path to figure out the container ids and require paths
          matches = path.match(containerRegEx);
          if (matches) {
            const flowPath = matches[1] || '';
            const flowId = matches[2];
            this.isAppFlow = matches[3] === 'app' && !flowPath && !flowId;
            const pageId = matches[4];

            if (!this.isAppFlow && !flowPath && !flowId) {
              // this is a root shell page
              this.pageId = pageId;
              this.pageRequirePath = '';
            } else if (!this.isAppFlow) {
              // flow id and its require path
              if (flowId) {
                this.flowId = flowId;
                this.flowRequirePath = `${flowPath}flows/${flowId}/`;
              }

              // page id and its require path
              if (pageId) {
                this.pageId = pageId;
                this.pageRequirePath = this.flowRequirePath;
              }
            }
          } else {
            throw new Error(`Failed to determine the leaf container from ${testConfig.path}`);
          }
        }
      }

      // the require path for the application and make sure we take into account the base url token
      this.appRequirePath = `${window.vbInitConfig.APP_BASE_URL}${appPath}${baseUrlToken}`;
    }

    /**
     * Load visual-runtime{-debug}.js and third-party-libs.js.
     *
     * @returns {Promise}
     */
    static loadRtLibraries() {
      this.loadRtLibrariesPromise = this.loadRtLibrariesPromise || new Promise((resolve, reject) => {
        const vbConfig = window.vbInitConfig;
        const JET_PATH = `${vbConfig.JET_CDN_PATH}${vbConfig.JET_CDN_VERSION}/`;
        const libPath = vbConfig.VB_CDN_PATH;
        const debug = vbConfig.DEBUG;

        // wait for the runtime to be ready
        const listener = (event) => {
          const data = event.data;
          const type = data.type;

          if (type === 'vbActionChainTesterHandShake') {
            window.removeEventListener('message', listener);
            this.rtLibrariesLoaded = true;
            resolve();
          }
        };
        window.addEventListener('message', listener);

        requirejs([`${JET_PATH}default/js/bundles-config${debug ? '-debug' : ''}.js`,
          `${libPath}lib/third-party-libs.js`], () => {
          requirejs([`${libPath}visual-runtime${debug ? '-debug' : ''}.js`],
            () => {
            }, reject);
        }, reject);
      });

      return this.loadRtLibrariesPromise;
    }

    /**
     * Override the requirejs baseUrl to the give baseUrl.
     *
     * @param baseUrl new base url
     */
    static overrideRequireBaseUrl(baseUrl) {
      // remember the base url for the test runner such as karma so we can restore it after the
      // action chain test finishes
      if (!this.originalBaseUrl) {
        this.originalBaseUrl = requirejs.toUrl('');
      }

      requirejs.config({ baseUrl });
    }

    /**
     * Restore the requirejs baseUrl to the original baseUrl
     */
    static restoreRequireBaseUrl() {
      // restore the original requirejs base url and only if originalBaseUrl is defined
      if (this.originalBaseUrl) {
        requirejs.config({
          baseUrl: this.originalBaseUrl,
        });

        this.originalBaseUrl = null;
      }
    }

    /**
     * Load the RT modules specified in mappings. Once the modules are loaded, it can be referenced
     * using this.rtModules[moduleId].
     *
     * For example, with the following mappings:
     * {
     *   Application: 'vb/private/stateManagement/application
     * },
     * you can refer to the loaded application class using this.rtModules.Application.
     *
     * @returns {Promise}
     */
    static loadRtModules() {
      this.loadRtModulesPromise = this.loadRtModulesPromise || new Promise((resolve, reject) => {
        this.rtModules = {};
        const rtModuleIds = Object.values(rtModuleMap);
        const moduleKeys = Object.keys(rtModuleMap);

        requirejs(rtModuleIds, (...modules) => {
          for (let i = 0; i < moduleKeys.length; i += 1) {
            this.rtModules[moduleKeys[i]] = modules[i];
          }

          // override methods on the RT modules for mocking purpose
          this.mockRtModuleMethods();

          resolve();
        }, reject);
      });

      return this.loadRtModulesPromise;
    }

    /**
     * This method is used to clean up the Application singleton after each run test run if
     * PRESERVE_LOADED_MODULES is true.
     */
    static resetApplication() {
      this.disposables.forEach((disposable) => {
        disposable.dispose();
      });
      this.disposables = [];

      if (this.loadedApplication) {
        Object.keys(this.loadedApplication).forEach((name) => {
          if (this.loadedApplication[name] instanceof Promise) {
            this.loadedApplication[name] = undefined;
          }
        });

        this.loadedApplication.exported = null;
        this.loadedApplication.extensionsArray = [];
        this.loadedApplication.extensions = {};
        this.loadedApplication.securityProvider = null;

        // delete mock descriptor for the application if any
        delete this.loadedApplication.mockDescriptor;

        this.loadedApplication = undefined;
      }
    }

    /**
     * Reset the tester.
     *
     * @returns {*}
     */
    static reset() {
      this.resetApplication();
      this.restoreRequireBaseUrl();
    }

    /**
     * Mock the default values for the constants specified in context for the given scopeName.
     *
     * @param scopeName $application, $global, $flow or $page
     * @param constantDefs the JSON descriptor for an application, flow or page container
     */
    mockConstants(scopeName, constantDefs, context = this.testConfig.context) {
      if (constantDefs) {
        // apply $base first if any
        if (context.$base) {
          this.mockConstants(scopeName.substring(1), constantDefs, context.$base);
        }

        const scopeContext = context[scopeName] || {};
        const mockConstants = scopeContext.constants || {};

        Object.keys(mockConstants).forEach((key) => {
          const constantDef = constantDefs[key];
          if (constantDef) {
            const mockConstant = mockConstants[key];

            if (constantDef.defaultValue) {
              constantDef.defaultValue = mockConstant;
            } else {
              // We get here is we are mocking extensions.constants. Simply replace the value.
              constantDefs[key] = mockConstant;
            }
          }
        });
      }
    }

    /**
     * Derive the scope name from the container, i.e., $application, $flow or $page.
     *
     * @param container an application, flow or a page container
     * @returns {string}
     */
    getScopeName(container) {
      let { className } = container;

      // strip off Extension since the scope name should be the same without it
      if (className.endsWith('Extension')) {
        className = className.substring(0, className.length - 9);
      }

      switch (className) {
        case 'Application':
          // if this is an app UI, the scope for application is $global
          return this.appUiId ? '$global' : '$application';
        case 'AppPackage':
          return '$application';
        case 'PackageFlow':
          return '$flow';
        case 'PackagePage':
          return '$page';
        case 'LayoutExtension':
          return '$layout';
        case 'PackageFragment':
        case 'FragmentExtension':
          return '$fragment';
        default:
          return `$${className.toLowerCase()}`;
      }
    }

    /**
     * Set up the constants context specified by the test for the given container.
     *
     * @param container an application, flow or a page container
     */
    initConstantsContext(container) {
      const definition = container.definition;

      // mock the constants for the container scope, i.e., $application, $flow or $page
      const scopeName = this.getScopeName(container);
      this.mockConstants(scopeName, definition.constants);

      if (definition.interface) {
        this.mockConstants(scopeName, definition.interface.constants);
      }

      if (definition.extensions) {
        this.mockConstants(scopeName, definition.extensions.constants);
      }
    }

    /**
     * Set up the initial variables context specified by the test for the given scopes.
     *
     * @param scopes the available scopes from a container
     * @param context mock context provided by the test
     */
    initVariablesContext(scopes, context = this.testConfig.context) {
      if (!scopes) {
        return;
      }

      Object.keys(context).forEach((scopeName) => {
        const scopeContext = context[scopeName];
        const scope = scopes[scopeName];

        // special check to make sure we don't process the flow scope when it's the same
        // as the application scope since the flow context here is referring to the page flow
        if (scopeName === 'flow' && scope === scopes.application) {
          return;
        }

        if (scopeName === '$base') {
          this.initVariablesContext(scope, scopeContext);
        } else if (scope && scopeContext.variables) {
          Object.keys(scopeContext.variables).forEach((variableName) => {
            const valueExpr = scopeContext.variables[variableName];

            // evaluate the source value expression
            let value = this.rtModules.StateUtils.getValueOrExpression(valueExpr, scopes);
            value = (typeof value === 'function') ? value() : value;

            // assign the value to the variable
            scope.variables[variableName] = value;
          });
        }
      });
    }

    /**
     * In the extension where the base application is not available, we need to provide
     * mock descriptors for application, flow and page containers. In addition, we need
     * to skip loading of the functions modules as well.
     *
     * @param container the container to mock
     */
    mockContainerIfNecessary(container) {
      if (this.extensionId
        && (!this.isSelfExtension || container.className === 'Application'
          || (container.className === 'AppPackage' && this.mockAppUi))) {
        container.skipLoadFunctions = true;
        container.mockDescriptor = {};

        // for non-appUi extension, the context is specified under $base
        const scopeName = this.getScopeName(container);
        const use$Base = this.appUiId && !this.isSelfExtension && scopeName !== '$global';
        let { context } = this.testConfig;
        context = use$Base ? context.$base : context;

        if (context) {
          // strip off $ if we are using $base
          const scopeDef = context[use$Base ? scopeName.substring(1) : scopeName];

          // inject interface into the mock descriptor
          if (scopeDef && scopeDef.interface) {
            container.mockDescriptor.interface = scopeDef.interface;
          }
        }
      }
    }

    /**
     * Load the container for real.
     *
     * @param container container to load
     * @returns {Promise<Container>}
     */
    load(container) {
      ActionChainTester.disposables.push(container);

      this.mockContainerIfNecessary(container);

      return container.loadDescriptor()
        .then(() => container.loadFunctionModule())
        .then(() => {
          container.initializeActionChains();

          // mock constants for the container
          this.initConstantsContext(container);

          // need to mock translation bundles before variable initialization in case
          // of default value expressions referencing translation bundles
          ActionChainTester.mockTranslationBundles(container.getAvailableContexts());

          return container.initAllVariableNamespace();
        })
        .then(() => {
          // need to set up the initial variable context here just in case the child container has a
          // variable expression referencing variables from this container
          this.initVariablesContext(container.getAvailableContexts());

          return container;
        });
    }

    /**
     * Load the application.
     *
     * @returns {Promise<Application>}
     */
    loadApplication() {
      const configLoader = this.rtModules.ConfigLoader;

      // Replace the extension registry with the mock
      // ConfigLoader.init will invoke it
      configLoader.getExtensionRegistry = () => {
        const extensionRegistry = new this.rtModules.ExtensionRegistry(this);
        return extensionRegistry.initialize().then(() => extensionRegistry);
      };

      this.mockContainerIfNecessary(this.application);

      return configLoader.init()
        .then(() => {
          this.application.definition = { routerStrategy: this.rtModules.Constants.RouterStrategy.PATH };
          this.rtModules.Router.application = this.application;
          this.application.createRouter();
          this.application.initReduxRouter();
          this.application.started = true;

          return this.application.loadRuntimeEnvironment();
        })
        .then(() => this.application.loadMetadata())
        .then(() => {
          const $application = this.testConfig.context.$application || {};

          // inject mockSecurityProvider to avoid issues loading third-party security providers
          configLoader.userConfig = {
            type: 'vbtu/mocks/mockSecurityProvider',
            configuration: {
              userOverride: $application.user, // allow overriding default test user
            },
          };

          // Reset the existing security provider promise so that the new
          // userConfig for the mock security provider get loaded by the application
          configLoader.loadSecurityProviderPromise = null;
        })
        .then(() => this.application.initAppUis())
        .then(() => this.load(this.application));
    }

    /**
     * Create a mock shell page.
     *
     * @param parent the parent container
     * @returns {*}
     */
    createShellPage(parent) {
      // create a fake shell page
      const shellPage = new this.rtModules.Page('shell', parent);
      shellPage.initializePromise = Promise.resolve();

      if (parent.className === 'AppPackage') {
        shellPage.package = parent;

        Object.defineProperties(shellPage, {
          extension: {
            value: parent.extension,
            enumerable: true,
            configurable: true,
          },
        });
      }

      return shellPage;
    }

    /**
     * Load the application UI.
     *
     * @param parent the parent container
     * @returns {Promise<T>}
     */
    loadAppUi(parent) {
      return Promise.resolve().then(() => {
        if (this.appUiId) {
          const shellPage = this.createShellPage(parent);

          // load the app package extension
          const extension = this.application.appUiInfos.getExtension(this.appUiId);

          return extension.init().then(() => {
            // create the app UI using the fake shell page as the parent
            const appUi = new this.rtModules.AppPackage(extension, { id: this.appUiId }, shellPage);

            // remember the appPackage so we can dispose it in afterRun
            this.appUi = appUi;

            return this.load(appUi)
              .then(() => appUi.initializeRequirejsMappings()) // process requirejs mapping in app.json
              .then(() => appUi);
          });
        }

        return parent;
      });
    }

    /**
     * Load the flow specified in the path for the container containing the action chain. If no flow is specified
     * in the path, return the application instead.
     *
     * @param parent the parent container
     * @returns {Promise<Flow|Application>}
     */
    loadFlow(parent) {
      return Promise.resolve().then(() => {
        if (this.flowId) {
          const shellPage = this.createShellPage(parent);

          // create the flow or packageFlow using the fake shell page as the parent
          let flow;
          if (this.appUiId) {
            flow = new this.rtModules.PackageFlow(this.flowId, shellPage,
              `applications/${this.appUiId}/${this.flowRequirePath}`);
          } else {
            flow = new this.rtModules.Flow(this.flowId, shellPage, this.flowRequirePath);
          }

          // remember the flow so we can dispose it in afterRun
          this.flow = flow;

          return this.load(flow);
        }

        return parent;
      });
    }

    /**
     * Load the page specified in the path for the container containing the action chain using the given flow as
     * the parent container. If no page is specified in the path, return the flow instead.
     *
     * @param parent the parent container
     * @returns {Promise<Page|Flow>}
     */
    loadPage(parent) {
      return Promise.resolve().then(() => {
        if (this.pageId) {
          // create a page or packagePage instance
          let page;
          if (this.appUiId) {
            page = new this.rtModules.PackagePage(this.pageId, parent);
          } else {
            page = new this.rtModules.Page(this.pageId, parent, this.pageRequirePath);
          }

          // remember the page so we can dispose it in afterRun
          this.page = page;

          return this.load(page);
        }

        return parent;
      });
    }

    /**
     * Load the layout containing the action chain.
     *
     * @returns {Promise<Container>}
     */
    loadLayout() {
      return this.loadApplication()
        .then((app) => {
          if (this.extensionId) {
            return this.loadAppUi(app);
          }
          return app;
        })
        .then((parent) => {
          // create a fake shell page
          const shellPage = this.createShellPage(parent);

          // calculate the absolute path to the layout
          const path = this.extensionId ? `vx/${this.extensionId}/${this.layoutRequirePath}` : this.layoutRequirePath;

          // create the layout using the fake shell page as the parent
          const layout = new this.rtModules.Layout(this.layoutId, shellPage, parent.extension, path);

          return this.load(layout);
        });
    }

    /**
     * Load the fragment containing the action chain.
     *
     * @returns {Promise<Container>}
     */
    loadFragment(parent) {
      return Promise.resolve().then(() => {
        // create a fake shell page
        const shellPage = this.createShellPage(parent);

        let FragmentClass;
        if (this.extensionId) {
          FragmentClass = this.rtModules.PackageFragment;
        } else {
          FragmentClass = this.rtModules.Fragment;
        }

        // create the fragment using the fake shell page as the parent
        const fragment = new FragmentClass(this.fragmentId, shellPage);

        // need to explicitly set the fragmentName to fragmentId
        fragment.fragmentName = this.fragmentId;

        return this.load(fragment);
      });
    }

    /**
     * Load the container specified by the path for the action chain. It can be either a layout, page, a flow or
     * an application.
     *
     * @returns {Promise<Container>}
     */
    loadContainer() {
      if (this.layoutId) {
        return this.loadLayout();
      }

      return this.loadApplication()
        .then((parent1) => this.loadAppUi(parent1))
        .then((parent2) => {
          if (this.fragmentId) {
            return this.loadFragment(parent2);
          }

          return this.loadFlow(parent2)
            .then((parent3) => this.loadPage(parent3));
        });
    }

    /**
     * This method is used to remove anything from the container descriptor we don't want present
     * when running the test, e.g., variable onValueChanged listeners and metadata.
     *
     * @param desc an application, flow or a page container descriptor
     */
    static mockContainerDescriptor(desc, container) {
      const variablesArray = [];
      if (desc.variables) {
        variablesArray.push(desc.variables);
      }

      if (desc.extensions && desc.extensions.variables) {
        variablesArray.push(desc.extensions.variables);
      }

      // Remove all the variable onValueChanged listeners for the given container definition
      variablesArray.forEach((variables) => {
        Object.keys(variables).forEach((key) => {
          delete variables[key].onValueChanged;

          // prevent variable values from leaking into subsequent tests
          delete variables[key].persisted;
        });
      });

      const constantsArray = [];
      if (desc.constants) {
        constantsArray.push(desc.constants);
      }

      if (desc.extensions && desc.extensions.constants) {
        constantsArray.push(desc.extensions.constants);
      }

      // Remove all the constant onValueChanged listeners for the given container definition
      constantsArray.forEach((constants) => {
        Object.keys(constants).forEach((key) => {
          delete constants[key].onValueChanged;
        });
      });

      // mock constants for extensions
      // TODO: find a better way to do this
      container.definition = desc;
      this.instance.initConstantsContext(container);

      // Stub out metadata definition so it doesn't get loaded since it may result in a REST call to retrieve
      // the metadata.
      if (desc.metadata) {
        desc.metadata = {};
      }

      return desc;
    }

    /**
     * Sanitize parameters to convert translation proxies into actual strings.
     *
     * @param value value to sanitize
     * @returns {string|{}|*}
     */
    static sanitizeParams(value) {
      if (!value) {
        return value;
      }

      // call toString on source if source is a translation proxy
      if (typeof value === 'object' && value[isTranslationProxy]) {
        return value.toString();
      }

      if (typeof value === 'string') {
        return value;
      }

      if (Array.isArray(value)) {
        return value.map((item) => this.sanitizeParams(item));
      }

      if (!this.rtModules.Utils.isPrototypeOfObject(value)) {
        return value;
      }

      if (this.rtModules.Utils.isObject(value)) {
        const obj = {};
        Object.keys(value).forEach((key) => {
          obj[key] = this.sanitizeParams(value[key]);
        });
        return obj;
      }

      return value;
    }

    /**
     * This method directly override methods on the RT modules for mocking purpose in case subclassing is not
     * possible, e.g., Container class hierarchy. Where subclassing is possible, the mocked
     * classes should go into the mocks folder.
     */
    static mockRtModuleMethods() {
      // prevent loading of page html
      this.rtModules.Container.prototype.templateLoader = () => Promise.resolve('');

      // mock RuntimeEnvironment.getApplicationDescriptor to load the mock descriptor in
      // the extension case where the base application is not available
      const tester = this;
      const origLoadAppDesc = this.rtModules.RuntimeEnvironment.prototype.getApplicationDescriptor;
      this.rtModules.RuntimeEnvironment.prototype.getApplicationDescriptor = function (resourceLocator) {
        if (tester.rtModules.Application.mockDescriptor) {
          return Promise.resolve(tester.rtModules.Application.mockDescriptor);
        }
        return origLoadAppDesc.call(this, resourceLocator);
      };

      // mock the descriptorLoader and functionsLoader for the following container types
      [
        this.rtModules.Container.prototype,
        this.rtModules.Layout.prototype,
        this.rtModules.LayoutExtension.prototype,
        this.rtModules.PackageFragment.prototype,
        this.rtModules.FragmentExtension.prototype,
      ].forEach((prototype) => {
        // mock descriptorLoader to load the mock descriptor in the extension case
        // where the base application artifacts are not available
        const origDescriptorLoader = prototype.descriptorLoader;
        // eslint-disable-next-line func-names
        prototype.descriptorLoader = function (resourceLocator) {
          return Promise.resolve().then(() => {
            if (this.mockDescriptor) {
              return this.mockDescriptor;
            }
            return origDescriptorLoader.call(this, resourceLocator);
          })
            .then((desc) => ActionChainTester.mockContainerDescriptor(desc, this));
        };

        // mock functionsloader to not load the functions modules in the extension case
        // where the base application artifacts are not available
        const origFunctionsLoader = prototype.functionsLoader;
        // eslint-disable-next-line func-names
        prototype.functionsLoader = function (resourceLocator) {
          if (this.skipLoadFunctions) {
            return Promise.resolve();
          }
          return origFunctionsLoader.call(this, resourceLocator);
        };
      });

      // prevent components, e.g., CCAs, from getting loaded
      this.rtModules.Container.prototype.loadImports = () => Promise.resolve();

      // prevent loading of translation bundles which will be mocked
      this.rtModules.Container.prototype.loadTranslationBundles = () => Promise.resolve();
      this.rtModules.Application.loadTranslationBundles = () => Promise.resolve();
      this.rtModules.Layout.prototype.loadTranslationBundles = () => Promise.resolve();

      // prevent loading of external plugins
      // eslint-disable-next-line no-underscore-dangle
      this.rtModules.ConfigLoader.constructor._getExternalPlugins = () => Promise.resolve();

      // mock runAction for JS action chain
      const origRunAction = this.rtModules.ActionChainUtils.runAction.bind(this.rtModules.ActionChainUtils);
      this.rtModules.ActionChainUtils.runAction = (actionModuleId, context, params, options = {}) => {
        // alias is alternate name for an action, e.g., Action.callRest is an alias for vb/action/builtin/restAction
        const { id, alias } = options;
        const mock = this.rtModules.ActionChainUtils.getInternalContext(context).mock || {};

        // get the generateId function from the current tester instance
        const { generateId } = this.instance;

        // sanitize params
        const sanitizedParams = this.sanitizeParams(params);

        // if no id is provided, generate one using the generateId function (which can be async)
        // based on either the alias or the actionModuleId
        return Promise.resolve()
          .then(() => (id || generateId(alias || actionModuleId, sanitizedParams)))
          .then((actualId) => {
            // check to see if the action has a mock
            let mockValues = mock[actualId];
            if (!mockValues && noopActions.indexOf(actionModuleId) > -1) {
              mockValues = {
                outcome: 'success',
                result: undefined,
              };
            }

            if (mockValues) {
              const mockParams = Object.assign({}, mockValues.parameters || sanitizedParams);

              // make the mock plus original module available via __MOCK__
              // eslint-disable-next-line no-underscore-dangle
              mockParams.__MOCK__ = Object.assign({}, mockValues,
                { origModule: actionModuleId, origParams: sanitizedParams });

              return origRunAction('vbtu/mocks/mockAction', context, mockParams, { id: actualId });
            }

            // run the action using a stub or normally
            return origRunAction(actionStubMap[actionModuleId]
              || actionModuleId, context, sanitizedParams, { id: actualId });
          });
      };

      // mock runActionSync to sanitized parameters
      const origRunActionSync = this.rtModules.ActionChainUtils.runActionSync.bind(this.rtModules.ActionChainUtils);
      this.rtModules.ActionChainUtils.runActionSync = (NewAction, actionModuleId, executionContext, params,
        options = {}) => {
        const sanitizedParams = this.sanitizeParams(params);
        return origRunActionSync(NewAction, actionModuleId, executionContext, sanitizedParams, options);
      };

      // mock addContextToAction for JS action chain
      const origAddContextToAction = this.rtModules.ActionChainUtils.addContextToAction
        .bind(this.rtModules.ActionChainUtils);
      this.rtModules.ActionChainUtils.addContextToAction = (actionType, action, context) => {
        if (actionType === 'vbtu/mocks/mockAction') {
          // make the writable context available to the mock action
          action.setContext(this.rtModules.ActionChainUtils.getWritableExecutionContext(context));
        } else {
          origAddContextToAction(actionType, action, context);
        }
      };
    }

    /**
     * This method is called before the test is run to set up the testing environment.
     *
     * @returns {Promise<any>}
     */
    beforeRun() {
      window.vbInitConfig = window.vbInitConfig || {};

      // start the VB runtime in action chain test mode
      window.vbInitConfig.TEST_MODE = 'actionChain';

      // turn off fancy mode and emoji
      window.vbInitConfig.LOG = {
        mode: 'test',
      };

      // Need to override window.customElements so we don't get an error when JET tries to register oj-module again
      window.customElements.define = () => {};

      // load the RT libraries
      return ActionChainTester.loadRtLibraries()
        .then(() => ActionChainTester.loadRtModules()) // load the RT modules
        .then(() => {
          // shortcut to rtModules
          this.rtModules = ActionChainTester.rtModules;

          // clone the testConfig to make sure we don't modify any shared context or mock
          this.testConfig = this.rtModules.Utils.cloneObject(this.testConfig);

          // for convenience
          this.application = this.rtModules.Application;

          ActionChainTester.loadedApplication = this.application;

          // For non-extension applications, override the requirejs base url to the application's require path
          // so all artifacts including ones loaded via dependencies can be loaded relative to the base url.
          // We don't to override requirejs base url for extension applications because all resources are loaded
          // relative to vx/extensionId.
          if (!this.extensionId) {
            ActionChainTester.overrideRequireBaseUrl(this.appRequirePath);
          }

          ActionChainTester.disposables.push(this.rtModules.StoreManager);
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }

    /**
     * Stub out actions we don't want executed during testing, e.g., navigateToPageAction, but don't want
     * the users to have to always provide mocks.
     */
    stubActions() {
      Object.keys(this.chainSource.actions)
        .forEach((actionId) => {
          const actionMd = this.chainSource.actions[actionId];
          const actionStub = actionStubMap[actionMd.module];

          if (actionStub) {
            actionMd.module = actionStub;
          }
        });
    }

    /**
     * Mock actions specified by the test mock. This is achieved by modifying the action chain definition.
     */
    mockActions() {
      const actions = this.chainSource.actions;
      const mock = this.testConfig.mock;

      Object.keys(actions).forEach((actionId) => {
        let mockValues = mock[actionId];
        const actionMd = actions[actionId];

        // if no mock is provided for an action that will fail without mock, provide a mock mock
        // for the action
        if (!mockValues && noopActions.indexOf(actionMd.module) > -1) {
          mockValues = {
            outcome: 'success',
            result: undefined,
          };
        }

        // make sure the mock exists
        if (mockValues) {
          const origModule = actionMd.module;
          actionMd.module = 'vbtu/mocks/mockAction';

          // set the parameters to ones provided by the mock
          actionMd.parameters = Object.assign({}, mockValues.parameters);

          // make the mock plus original module available via __MOCK__
          // eslint-disable-next-line no-underscore-dangle
          actionMd.parameters.__MOCK__ = Object.assign({}, mockValues, { origModule });
        }
      });
    }

    /**
     * Mock translation bundles so they return the translation keys instead of the actual
     * translated strings.
     *
     * @param scopes scopes to mock
     * @returns {String|string|any}
     */
    static mockTranslationBundles(scopes) {
      const handler = {
        get(target, propKey) {
          if (propKey === isTranslationProxy) {
            return true;
          }

          if (propKey === 'format') {
            return function (bundle, key) {
              return `${target}.${bundle}.${key}`;
            };
          }
          const prop = target[propKey];
          if (typeof prop === 'function') {
            return prop.bind(target);
          }

          if (typeof propKey !== 'string') {
            if (propKey.toString() === 'Symbol(Symbol.toPrimitive)') {
              return target.toString.bind(target);
            }

            if (propKey.toString() === 'Symbol(Symbol.toStringTag)') {
              return 'string';
            }
          }

          // eslint-disable-next-line no-new-wrappers
          return new Proxy(new String(`${target}.${propKey}`), handler);
        },
      };

      ['$global', '$application', '$flow', '$page', '$layout'].forEach((scopeName) => {
        const scope = scopes[scopeName];

        if (scope) {
          // eslint-disable-next-line no-new-wrappers
          scope.translations = new Proxy(new String(`${scopeName}.translations`), handler);
        }
      });
    }

    /**
     * Apply mock to module functions or variable factory instance methods by proxying the given
     * context object. The following is an example mock:
     *
     * {
     *   $page: {
     *     functions: {
     *       foo: [
     *         {
     *           return: 'mocked_foo_1',
     *         },
     *         {
     *           parameters: ['abc', 123, { foo: 'bar', bar: 'foo' }],
     *           return: 'mocked_foo_2',
     *         },
     *       ],
     *     },
     *     variables: {
     *       incidentsListLDPV: {
     *         getCapability: [
     *           {
     *             return: 'mocked_capability_1',
     *           },
     *           {
     *             parameters: ['sort', 'filter'],
     *             return: 'mocked_capability_2',
     *           },
     *         ],
     *       },
     *     },
     *   },
     * }
     *
     * See createMockFunction for details on how a mock return value is looked up for a function call.
     *
     * @param context the context object for the JS chain to be proxied
     * @returns {*}
     */
    mockFunctions(context) {
      const { mock } = this.testConfig;

      // nothing to mock
      if (Object.keys(mock).length === 0) {
        return context;
      }

      // proxy context object
      return new Proxy(context, {
        get: (scopes, scopeName) => {
          const scope = scopes[scopeName];

          const scopeMock = mock[scopeName];
          if (!scopeMock) {
            return scope;
          }

          // proxy scope, e.g., $page
          return new Proxy(scope, {
            get: (namespaces, nsName) => {
              const namespace = namespaces[nsName];

              switch (nsName) {
                case 'functions':
                  return scopeMock.functions ? this.mockModuleFunction(namespace, scopeMock.functions) : namespace;
                case 'variables':
                  return scopeMock.variables ? this.mockVariableFunction(namespace, scopeMock.variables) : namespace;
                default:
                  return namespace;
              }
            },
          });
        },
      });
    }

    /**
     * Create a wrapper function that looks up a mock return value by matching function call arguments
     * with the parameters specified in the mock. If no match is found, the mock without parameters
     * (the default mock) is used instead.
     *
     * For example, given the following array of mock return values:
     *
     * [
     *   {
     *     return: 'mocked_foo_1',
     *   },
     *   {
     *     parameters: ['abc', 123, { foo: 'bar', bar: 'foo' }],
     *     return: 'mocked_foo_2',
     *   },
     * ]
     *
     * If function call arguments match ['abc', 123, { foo: 'bar', bar: 'foo' }], 'mocked_foo_2' will
     * be returned. Otherwise, 'mocked_foo_1' is returned.
     *
     * In the case where no default mock is found, the original function will be called.
     *
     * @param origFunc original function which will be invoked if no mock is found
     * @param functionMockArr an array of mock return values
     * @returns {(function(...[*]=): *)|*}
     */
    createMockFunction(origFunc, functionMockArr) {
      if (typeof origFunc !== 'function' || !functionMockArr || functionMockArr.length === 0) {
        return origFunc;
      }

      return (...args) => {
        let defaultFunctionMock = null;

        const functionMock = functionMockArr.find((funcMock) => {
          let { parameters } = funcMock;

          // found default function mock
          if (!parameters || parameters.length === 0) {
            defaultFunctionMock = funcMock;
            parameters = [];
          }

          return !this.rtModules.jsondiff.diff(args, parameters);
        }) || defaultFunctionMock;

        return functionMock ? functionMock.return : origFunc(...args);
      };
    }

    /**
     * Apply mock to a module function.
     *
     * @param module the module functions
     * @param functionsMock the mock for the functions namespace
     * @returns {*}
     */
    mockModuleFunction(module, functionsMock) {
      return new Proxy(module, {
        get: (functions, funcName) => this.createMockFunction(functions[funcName], functionsMock[funcName]),
      });
    }

    /**
     * Apply mock to a factory instance method.
     *
     * @param variablesNs the variables namespace
     * @param variablesMock the mock for the variables namespace
     * @returns {*}
     */
    mockVariableFunction(variablesNs, variablesMock) {
      return new Proxy(variablesNs, {
        get: (variables, varName) => {
          const variable = variables[varName];
          const variableMock = variablesMock[varName];

          if (!variableMock) {
            return variable;
          }

          return new Proxy(variable, {
            get: (varProps, varPropName) => {
              const varProp = varProps[varPropName];

              if (varPropName !== 'instance') {
                return varProp;
              }

              return new Proxy(varProp, {
                get: (instance, funcName) => this.createMockFunction(
                  instance[funcName], variableMock[funcName],
                ),
              });
            },
          });
        },
      });
    }

    /**
     * Call the action chain in the given container.
     *
     * @param cont the container in which to call the action chain.
     * @returns {Promise<Object>}
     */
    callActionChain(cont) {
      const container = this.extensionId && !this.isSelfExtension ? cont.extensionsArray[0] : cont;
      const chainId = this.testConfig.chainId;
      const scopes = container.getAvailableContexts();

      // override variable values using values specified in the context
      this.initVariablesContext(scopes);

      // get the parameters from the test context
      const params = {};
      const initContext = this.testConfig.context;
      const $chain = initContext.$chain || {};
      Object.assign(params, $chain.variables, $chain.constants, $chain.parameters,
        initContext.$variables, initContext.$constants, initContext.$parameters);

      const context = {
        application: container.application,
        parent: container.parent,
        container,
        chains: container.chains,
      };

      let executionContext = this.rtModules.ActionChainUtils.createExecutionContext(chainId, context, scopes,
        this.isJsChain);

      // make mock available to the overridden Actions api
      this.rtModules.ActionChainUtils.getInternalContext(executionContext).mock = this.testConfig.mock;

      // handle application, flow or page level action chains
      // Resolve the scope (application, flow, page) from the actionId if one exist
      // and get the action metadata.
      return this.rtModules.StateUtils.resolveChain(chainId, context.container.scopeResolver, this.isJsChain)
        .then((chainSource) => {
          this.chainSource = chainSource;

          if (!this.chainSource) {
            throw new Error(`Action chain ${chainId} does not exist.`);
          }

          // make sure actions is defined
          if (!this.isJsChain) {
            this.chainSource.actions = this.chainSource.actions || {};

            // mock actions
            this.mockActions();

            // stub actions that we don't want executed during the test
            this.stubActions();
          } else {
            // for JS chains, we need to be able to mock direct functions calls on function modules and
            // variable factory instances
            executionContext = this.mockFunctions(executionContext);
          }

          // create the action chain
          const actionChain = this.createChain(chainId);

          // evaluate input parameters
          const inputParamValues = this.rtModules.StateUtils.deepEval(params, scopes);

          const debugStream = this.rtModules.ActionChainUtils.getDebugStream(executionContext);

          debugStream.start(inputParamValues);
          return actionChain.run(executionContext, inputParamValues)
            .catch((error) => {
              if (this.isJsChain) {
                debugStream.logError(error);
              } else {
                throw error;
              }
            })
            // get the content of the action chain's debug stream which will have all execution states
            // of all the actions as well as the action chain
            .then((returnValue) => {
              debugStream.end(returnValue);
              return debugStream.content;
            });
        });
    }

    createChain(chainId) {
      if (this.isJsChain) {
        const ChainClass = this.chainSource;
        return new ChainClass();
      }
      return new this.rtModules.ActionChain(chainId.split(':')[0], this.chainSource);
    }

    /**
     * The result from RT has the following format:
     * {
     *   $chain: {
     *     // context before the action chain is executed
     *     beforeContext: {
     *       $page: {
     *         variables: {
     *           pageVar1: 'foo'
     *         }
     *       },
     *       $chain: {
     *         variables: {
     *           chainVar1: 'bar'
     *         }
     *       }
     *     },
     *
     *     // context after the action chain is executed
     *     afterContext: {
     *       $page: {
     *         variables: {
     *           pageVar1: 'bar'
     *         }
     *       },
     *       $chain: {
     *         variables: {
     *           chainVar1: 'foo'
     *         }
     *       }
     *     },
     *
     *     // execution state for all the actions
     *     actions: [
     *       {
     *         // action id plus a randomly generated execution id
     *         id: 'action1_xxxxxx',
     *
     *         // context before the action is executed
     *         beforeContext: { ... },
     *
     *         // context after the action is executed
     *         afterContext: { ... },
     *
     *         // evaluated input parameters to the action
     *         parameters: { ... },
     *
     *         // outcome of the action
     *         outcome: {
     *           name: 'success',
     *           result: 'barfoo',
     *         }
     *       }
     *     ],
     *
     *     // results of all the actions
     *     results: {
     *       action1: 'barfoo'
     *     }
     *   }
     * }
     *
     * We transform it to the following format expected by the DT test generator.
     *
     * {
     *   $application: {
     *     variables: {
     *       appVar1: 'foo'
     *     }
     *   },
     *   $package: {
     *     variables: {
     *       appPackVar1: 'foo'
     *     }
     *   },
     *   $flow: {
     *     variables: {
     *       flowVar1: 'bar'
     *     }
     *   },
     *   $page: {
     *     variables: {
     *       pageVar1: 'foobar'
     *     }
     *   },
     *   $layout: {
     *     variables: {
     *       layoutVar1: 'foobar'
     *     }
     *   },
     *   $chain: {
     *     results: {
     *       action1: 'barfoo'
     *     }
     *   },
     *   $actions: {
     *     action1: {
     *       'outcome': 'success'
     *       'inputs': '....'
     *     }
     *   }
     * }
     *
     * Note that $application, $flow and $page are from the afterContext. We may expose the beforeContext in
     * the future.
     *
     * @param result the result to transform
     */
    static transformResult(result) {
      const obj = {};

      obj.$chain = result.$chain;

      ['$global', '$application', '$flow', '$page', '$layout', '$fragment', '$base'].forEach((scope) => {
        obj[scope] = result.$chain.afterContext[scope];
      });

      // copy over the $chain variables and constants from the afterContext
      if (result.$chain.afterContext.$chain) {
        obj.$chain.variables = result.$chain.afterContext.$chain.variables;
        obj.$chain.constants = result.$chain.afterContext.$chain.constants;
        delete obj.$chain.variables.vb_parameters;
        delete obj.$chain.variables.vb_results;
      }

      delete obj.$chain.beforeContext;
      delete obj.$chain.afterContext;

      // delete xxx_value and xxx_internalState variables for extended type variables
      ['$global', '$application', '$flow', '$page', '$layout', '$fragment', '$chain'].forEach((scope) => {
        const scopeContext = obj[scope];

        if (scopeContext && scopeContext.variables) {
          const variables = scopeContext.variables;

          const varsToDelete = Object.keys(variables).filter((varName) =>
            // eslint-disable-next-line no-prototype-builtins,implicit-arrow-linebreak
            variables.hasOwnProperty(`${varName}_value`) && variables.hasOwnProperty(`${varName}_internalState`));

          varsToDelete.forEach((varName) => {
            delete variables[`${varName}_value`];
            delete variables[`${varName}_internalState`];
          });
        } else if (scope !== '$chain') {
          delete obj[scope];
        }
      });

      obj.$actions = {};

      // process in reverse order so the last occurrence of multiple executions of an action ends up on $action
      result.$chain.actions.reverse().forEach((action) => {
        let id = action.id;

        // strip off unique id
        const index = action.id.lastIndexOf('_');
        if (index !== -1) {
          id = id.substring(0, index);
        }

        delete action.beforeContext;
        delete action.afterContext;

        if (action.outcome) {
          action.inputs = action.outcome.inputs;
          action.outcome = action.outcome.name;
        }

        let actionArr = obj.$actions[id];
        if (!actionArr) {
          actionArr = [];
          Object.assign(actionArr, action);
          actionArr.results = obj.$chain.results[id];
          obj.$actions[id] = actionArr;
        }

        actionArr.push(action);
      });

      delete result.$chain.actions;

      return obj;
    }

    /**
     * Run the test.
     *
     * @returns {Promise<Object>}
     */
    run() {
      return this.loadContainer()
        .then((container) => this.callActionChain(container))
        .then((result) => ActionChainTester.transformResult(result));
    }

    /**
     * Clean up after the test is run.
     *
     * @returns {Promise}
     */
    static afterRun() {
      return Promise.resolve()
        .then(() => {
          // turn off test mode
          delete window.vbInitConfig.TEST_MODE;

          // remove LOG setting
          delete window.vbInitConfig.LOG;

          // reset the tester for the next test
          return ActionChainTester.reset();
        });
    }

    /**
     * Run the test by calling beforeRun -> run -> afterRun.
     *
     * @returns {Promise<Object>}
     */
    runTest() {
      // The mutex is used to prevent two or more concurrent tests from running at the same time. This
      // can happen if one test throws an uncaught exception (via a timer, e.g.) that causes karma to
      // fail the test and start a new one before the current test finishes and cleans up.
      if (!ActionChainTester.mutex) {
        ActionChainTester.mutex = Promise.resolve()
          .then(() => this.beforeRun()
            .then(() => this.run())
            .finally(() => ActionChainTester.afterRun()))
          .finally(() => {
            ActionChainTester.mutex = null;
          });

        return ActionChainTester.mutex;
      }

      return ActionChainTester.mutex
        .catch(() => {
          // ignore
        })
        .then(() => this.runTest());
    }
  }

  /**
   * Provides access to the current instance of ActionChainTester.
   *
   * @type {ActionChainTester}
   */
  ActionChainTester.instance = undefined;

  /**
   * Used to prevent two or more concurrent tests from running.
   *
   * @type {Promise}
   */
  ActionChainTester.mutex = undefined;

  /**
   * Holds all the containers that need to be disposed.
   *
   * @type {*[]}
   */
  ActionChainTester.disposables = [];

  return ActionChainTester;
});

