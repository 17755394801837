/* eslint-disable max-len */

'use strict';

define('vbtu/mocks/mockExtensionRegistry',['vb/private/vx/v2/extensionRegistry', 'vb/private/utils', 'vb/private/constants'],
  (ExtensionRegistry, Utils, Constants) => {
    /**
     * This class is used to mock the V2 extension registry when running action chain tests from an extension.
     */
    class MockExtensionRegistry extends ExtensionRegistry {
      constructor(tester) {
        super(() => Promise.resolve(tester.application.definition), () => Promise.resolve(tester.application.runtimeEnvironment));
        this.tester = tester;
      }

      initialize() {
        this.initiateLoadManifest();
        return Promise.resolve();
      }

      initiateLoadManifest() {
        this.fetchManifestPromise = Promise.resolve()
          .then(() => {
            const manifest = { extensions: [] };
            const vbConfig = window.vbInitConfig || {};
            let baseUrl = vbConfig.APP_BASE_URL || '';

            // don't load the manifest.json if there is no extension id which means this is a normal app
            if (!this.tester.extensionId || !baseUrl) {
              return manifest;
            }

            // mark this test as running under an unified app
            window.vbInitConfig.APP_TYPE = 'unified';

            // remove trailing /
            if (baseUrl.endsWith('/')) {
              baseUrl = baseUrl.substring(0, baseUrl.length - 1);
            }

            return Utils.loadAndParse(`${baseUrl}/manifest.json`)
              .then((ext) => this.loadRequirejsInfo(baseUrl, ext)
                .then((rjsInfo) => {
                  const extension = ext;

                  extension.baseUrl = baseUrl;
                  manifest.extensions.push(extension);
                  manifest.requirejsInfo = [
                    {
                      id: extension.id,
                      version: extension.version,
                      metadata: rjsInfo,
                    }];

                  // Convert the dependencies from an array into a map
                  if (extension.dependencies) {
                    const { extensionId, isSelfExtension } = this.tester;
                    const dependencies = extension.dependencies;
                    extension.dependencies = {};

                    if (!isSelfExtension) {
                      dependencies.forEach((dep) => {
                        // make sure we only include the extension that the test depends on
                        if (dep.id === extensionId) {
                          extension.dependencies[dep.id] = dep.version;
                        }
                      });
                    }
                  }

                  if (this.tester.layoutId) {
                    this.mockLayoutManifest(manifest);
                  } else if (this.tester.fragmentId) {
                    this.mockFragmentManifest(manifest);
                  } else {
                    this.mockAppUiManifest(manifest);
                  }

                  return manifest;
                }))
              .catch(() => manifest);
          });
      }

      /**
       * Override the default implementation to return the mock manifest.
       *
       * @returns {Promise<Object>}
       * @private
       */
      _loadManifest() {
        return this.fetchManifestPromise;
      }

      /**
       * Load requirejs-info.json.
       *
       * @param baseUrl requirejs baseUrl from which to load the file
       * @param extension extension where requirejs-info.json should be loaded from
       * @returns {Promise<Object>}
       */
      loadRequirejsInfo(baseUrl, extension) {
        return Promise.resolve().then(() => {
          const rjsInfo = 'extension-digest/requirejs-info.json';

          // only load requirejs-info.json if it's listed in the extension
          if (extension.files.includes(rjsInfo)) {
            return Utils.loadAndParse(`${baseUrl}/${rjsInfo}`);
          }

          return {};
        });
      }

      /**
       * Mock extension manifest for an app ui and app ui extension.
       *
       * @param manifest manifest to mock
       */
      mockAppUiManifest(manifest) {
        const [extension] = manifest.extensions;
        const { extensionId, appUiId, isSelfExtension } = this.tester;

        // filter out files we don't want loaded for this test except for files
        // under ui/${extensionId} and ui/base
        const pathPrefix = `${Constants.DefaultPaths.UI}${extensionId}`;
        const basePrefix = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.BASE}`;
        extension.files = extension.files
          .filter((file) => (file.startsWith(pathPrefix) || file.startsWith(basePrefix)));

        // Create a fake extension for the base extension. Note that the files listed in this extension
        // will not actually be loaded but stubbed out by the tester.
        if (appUiId && !isSelfExtension) {
          const baseExtension = {
            id: extensionId,
            baseUrl: extension.baseUrl,
            files: [],
          };

          const basePathPrefix = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}`;
          baseExtension.files = extension.files.map((file) => file
            .replace(pathPrefix, basePathPrefix).replace('-x.', '.'));

          // make sure we always have app.json
          const appJsonPath = `${basePathPrefix}/applications/${appUiId}/app.json`;
          if (!baseExtension.files.includes(appJsonPath)) {
            baseExtension.files.push(appJsonPath);
          }

          manifest.extensions.push(baseExtension);
        }
      }

      /**
       * Mock extension manifest for layouts and layout extensions.
       *
       * @param manifest manifest to mock
       */
      mockLayoutManifest(manifest) {
        const [extension] = manifest.extensions;
        const { extensionId, appUiId, isSelfExtension } = this.tester;

        // filter out files that are not under dynamicLayouts/extensionId
        const layoutPrefix = `${Constants.DefaultPaths.LAYOUTS}${extensionId}/`;
        extension.files = extension.files.filter((file) => file.startsWith(layoutPrefix));

        const appPath = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${Constants
          .DefaultPaths.APPLICATIONS}${appUiId}/app.json`;

        if (isSelfExtension) {
          // Inject a mock app ui and instruct the tester to create a mock app ui container by
          // setting mockAppUi to true.
          extension.files.push(appPath);
          this.tester.mockAppUi = true;
        }

        // Create a fake extension for the base extension. Note that the files listed in this extension
        // will not actually be loaded but stubbed out by the tester.
        const baseExtension = {
          id: extensionId,
          baseUrl: extension.baseUrl,
          files: [],
        };

        const baseLayoutPrefix = `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`;
        baseExtension.files = extension.files.map((file) => file
          .replace(layoutPrefix, baseLayoutPrefix).replace('-x.', '.'));

        // push a fake app.json
        baseExtension.files.push(appPath);

        manifest.extensions.push(baseExtension);
      }

      /**
       * Mock extension manifest for fragments and fragment extensions.
       *
       * @param manifest manifest to mock
       */
      mockFragmentManifest(manifest) {
        const [extension] = manifest.extensions;
        const { extensionId, appUiId, isSelfExtension } = this.tester;

        // filter out files that are not under ui/{extensionId}/fragments
        const fragmentPrefix = `${Constants.DefaultPaths.UI}${extensionId}/fragments/`;
        extension.files = extension.files.filter((file) => file.startsWith(fragmentPrefix));

        const appPath = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${Constants
          .DefaultPaths.APPLICATIONS}${appUiId}/app.json`;

        if (isSelfExtension) {
          // Inject a mock app ui and instruct the tester to create a mock app ui container by
          // setting mockAppUi to true.
          extension.files.push(appPath);
          this.tester.mockAppUi = true;
        } else {
          // Create a fake extension for the base extension. Note that the files listed in this extension
          // will not actually be loaded but stubbed out by the tester.
          const baseExtension = {
            id: extensionId,
            baseUrl: extension.baseUrl,
            files: [],
          };

          const baseFragmentPrefix = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
          baseExtension.files = extension.files.map((file) => file
            .replace(fragmentPrefix, baseFragmentPrefix).replace('-x.', '.'));

          // push a fake app.json
          baseExtension.files.push(appPath);

          manifest.extensions.push(baseExtension);
        }
      }
    }

    return MockExtensionRegistry;
  });

